import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'

import './ArticleListPageNav.scss'


// =============================================================================
const LabelLink = ({ enabled, rel, url }) => {
  const body = rel === 'prev'
    ? <>←<span className="label">&nbsp;Previous Page</span></>
    : <><span className="label">Next Page&nbsp;</span>→</>

  return enabled ? (
    <Link to={url} rel={rel}>
      {body}
    </Link>
  ) : (
    <span className="link" rel={rel}>
      {body}
    </span>
  )
}


// =============================================================================
const ArticleListPageNav = ({
  currentPage,
  numPages,
  baseUrl,
  firstPageUrl,
}) => {
  // Sanitise the optional arguments.
  baseUrl = baseUrl || ''
  firstPageUrl = firstPageUrl || baseUrl

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? `${firstPageUrl}` : `${baseUrl}/${currentPage - 1}`
  const nextPage = `${baseUrl}/${currentPage + 1}`

  return (
    <nav className="ArticleListPageNav">
      <ul>
        <li>
          <LabelLink rel="prev" url={prevPage} enabled={!isFirst} />
        </li>
        {Array.from({ length: numPages }, (_, i) => (
          <li key={`page-${i}`}>
            <Link
              to={i === 0 ? firstPageUrl : `${baseUrl}/${i + 1}`}
              className={i + 1 === currentPage ? 'current' : ''}
            >
              {i + 1}
            </Link>
          </li>
        ))}
        <li>
          <LabelLink rel="next" url={nextPage} enabled={!isLast} />
        </li>
      </ul>
    </nav>
  )
}

// -----------------------------------------------------------------------------
ArticleListPageNav.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  baseUrl: PropTypes.string,
  firstPageUrl: PropTypes.string,
}

// -----------------------------------------------------------------------------
export default ArticleListPageNav
