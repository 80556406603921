import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TagLink from './TagLink'
import TitleBlock from './TitleBlock'

import './ArticleTitleBlock.scss'


// =============================================================================
const ArticleTitleBlock = ({
  title, date, isDraft, tags, timeToRead, path, small
}) => {
  let tagsHtml;

  if (tags) {
    tagsHtml = (
      <div className="widget tags">
        <FontAwesomeIcon icon="tags" />&nbsp;
        <ul>
          {tags.map(tag => (
            <li key={tag}>
              <TagLink tagName={tag} small={small} />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <TitleBlock title={title} small={small} path={path}>
      <time>{date}</time>
      {isDraft &&
        <div className="widget draft">
          <FontAwesomeIcon icon="hammer" />&nbsp;Draft
        </div>}
      <div className="widget">
        <FontAwesomeIcon icon="clock" />&nbsp;{timeToRead} min read
      </div>
      {tagsHtml}
    </TitleBlock>
  )
}

// -----------------------------------------------------------------------------
ArticleTitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  timeToRead: PropTypes.string.isRequired,
  path: PropTypes.string,
  small: PropTypes.bool,
}

// -----------------------------------------------------------------------------
export default ArticleTitleBlock
