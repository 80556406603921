import React from 'react'

import { graphql } from 'gatsby'

import ArticleListItem from '../components/ArticleListItem'

import ArticleListPage from '../containers/ArticleListPage'


// =============================================================================
const IndexPage = ({ data, pageContext: { numPages, currentPage } }) => {
  const posts = data.allMdx.nodes

  return (
    <ArticleListPage
      currentPage={currentPage}
      numPages={numPages}
      baseUrl="/index"
      firstPageUrl="/"
    >
      {posts.map(post => (
        <ArticleListItem
          key={post.id}
          isDraft={post.draft}
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          tags={post.frontmatter.tags}
          timeToRead={post.timeToRead}
          path={post.path}
          excerpt={post.frontmatter.excerpt || post.excerpt} />
      ))}
    </ArticleListPage>
  )
}

// -----------------------------------------------------------------------------
export default IndexPage


// =============================================================================
export const query = graphql`
  query IndexQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { publish: { eq: true } },
      sort: { order: DESC, fields: [frontmatter___date] },
      skip: $skip,
      limit: $limit
    ) {
      nodes {
        excerpt(pruneLength: 250)
        id
        path
        draft
        timeToRead
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          excerpt
        }
      }
    }
  }
`