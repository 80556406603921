import React from 'react'
import PropTypes from 'prop-types'

import ArticleTitleBlock from './ArticleTitleBlock'

import './ArticleListItem.scss'


// =============================================================================
const ArticleListItem = ({
  title, date, isDraft, tags, timeToRead, excerpt, path
}) => (
  <article className="ArticleListItem">
    <ArticleTitleBlock
      small
      title={title}
      date={date}
      isDraft={isDraft}
      tags={tags}
      timeToRead={timeToRead}
      path={path} />
    <p>{excerpt}</p>
  </article>
)

// -----------------------------------------------------------------------------
ArticleListItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  timeToRead: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

// -----------------------------------------------------------------------------
export default ArticleListItem
