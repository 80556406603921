import React from 'react'
import PropTypes from 'prop-types'

import ArticleListItem from '../components/ArticleListItem'
import ArticleListPageNav from '../components/ArticleListPageNav'

import Page from './Page'


// =============================================================================
const ArticleListPage = ({
  currentPage,
  numPages,
  firstPageUrl,
  baseUrl,
  title,
  children,
}) => (
  <Page title={title} educateChildren={false}>
    {children}
    <ArticleListPageNav
      currentPage={currentPage}
      numPages={numPages}
      firstPageUrl={firstPageUrl}
      baseUrl={baseUrl}
    />
  </Page>
)

// -----------------------------------------------------------------------------
ArticleListPage.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  firstPageUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.arrayOf(ArticleListItem).isRequired,
}

// -----------------------------------------------------------------------------
export default ArticleListPage
